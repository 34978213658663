import React from "react"
import { useState } from "react"
import { Box, Grid, Image } from "../system"
import Upload from "./upload"
import { useStore } from "../../utils/store"

import Question from "./question"

const StageQuestion = ({ id, title, sub }) => {
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)

  const addImage = useStore(state => state.addImage)
  const uploads = useStore(state => state.uploads)

  const uploadImage = async (e, location) => {
    if (e.target.value.length <= 0) return
    // const fileName = e.target.files[0].name
    const file = e.target.files[0]
    // const id = e.target.id

    try {
      setLoading(true)
      console.log(`uploading to ${process.env.GATSBY_SANITY_DATASET}`)
      let response = await fetch(
        `https://${process.env.GATSBY_SANITY_PROJECT_ID}.api.sanity.io/v1/assets/images/${process.env.GATSBY_SANITY_DATASET}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${process.env.GATSBY_SANITY_TOKEN}`,
          },
          body: file,
        }
      )

      await response.json().then(data => {
        // createImageDoc(data.document)
        console.log("Image Uploaded: ", data)
        const { _id, url } = data.document
        const image = {
          _type: "reference",
          _ref: _id,
          url: url, // filter out image
        }
        const ref = {
          _type: "reference",
          _ref: _id,
        }

        setImages(images.concat(image))
        setLoading(false)
        // updateQuestions(id, title, image)
        addImage(title, ref, uploads)
      })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Box mb={5}>
      <Question title={title} sub={sub} />
      <Grid gridGap={20} gridTemplateColumns="1fr 1fr 1fr 1fr" my={4}>
        {images &&
          images.map((i, key) => (
            <Image
              key={key}
              src={i.url}
              alt=""
              width={["80vw", "20vw"]}
              height={["80vw", "20vw"]}
              borderRadius={5}
              style={{ objectFit: "cover" }}
            />
          ))}
        <Upload
          type="image"
          accept="image/*"
          loading={loading}
          id={id}
          name="imageUpload"
          placeholder="upload image"
          onChange={e => uploadImage(e)}
        />
      </Grid>
    </Box>
  )
}

export default StageQuestion
