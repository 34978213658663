import React, { useState } from "react"
import { Flex, Input, Label } from "../system"
import { Image } from "../Icons"
import Loading from "../Loading"

const Upload = ({ id, type, onChange, name, placeholder, loading, accept }) => {
  const [hover, setHover] = useState(false)

  return (
    <Flex
      height={["80vw", "20vw"]}
      width={["80vw", "20vw"]}
      borderRadius={5}
      border={hover ? "2px solid black" : "2px solid #CECECE"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Input
        type="file"
        onChange={onChange}
        placeholder={placeholder}
        id={id}
        accept={accept}
        style={{ display: "none" }}
      />
      <label htmlFor={id} style={{ cursor: "pointer" }}>
        <Flex
          height={["80vw", "20vw"]}
          width={["80vw", "20vw"]}
          flexDirection="column"
        >
          {loading ? (
            <Loading />
          ) : (
            <Image color={hover ? "black" : "#cecece"} />
          )}
          {!loading && (
            <Label color={hover ? "black" : "faded"} down>
              Upload Image
            </Label>
          )}
        </Flex>
      </label>
    </Flex>
  )
}

export default Upload
