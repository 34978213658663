import React from "react"
import { Flex, Title, Label } from "../system"

const Question = ({ title, sub }) => (
  <Flex flexDirection="column" alignItems="flex-start">
    <Title fontSize={[5, 6]} mb={[2, 1]} textAlign="left">
      {title}
    </Title>
    <Label down color="secondary">
      {sub}
    </Label>
  </Flex>
)

export default Question
