import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import { useAuth0 } from "@auth0/auth0-react"
import Layout from "../../components/Community/Layout"

import { client } from "../../sanity"
import { Flex, Label, Title, Box, Button } from "../../components/system"
import { useStore } from "../../utils/store"

import Video from "../../components/Video"
import StageQuestion from "../../components/Community/StageQuestion"

const template = [
  {
    id: 0,
    title: "1. Upload Patterns",
    sub: "Provide pictures of garment Patterns as follows – pattern pieces front garment, pattern pieces back garment, pattern pieces accessories garment. Others pattern pieces if applicable. Pictures must be good quality, allowing detailed view of patterns and details as described in the Technical Card.",
    uploads: [],
  },
  {
    id: 1,
    title: "2. Upload Toile",
    sub: "Provide pictures of the garment toile as follows - back, front and sides. Provide pictures of garment toile fitting as follows - back, front and sides. Pictures must be good quality, allowing detailed view of the garment and fit described in the Technical Card. formats JPG, PNG",
    uploads: [],
  },
]

const StageTwo = () => {
  const { user } = useAuth0()
  const [expert, setExpert] = useState()

  const [questions, setQuestions] = useState([])
  const [submitting, setSubmitting] = useState(false)

  const uploads = useStore(state => state.uploads)
  const clearUploads = useStore(state => state.clearUploads)

  useEffect(() => {
    // TODO: CLEAR STORE UPOADS
    const query = '*[_type == "user" && uuid == $uuid]{assigned->{email}}'
    const params = { uuid: user.sub }

    client.fetch(query, params).then(data => {
      setExpert(data[0].assigned.email)
    })

    return () => clearUploads()
  }, [user])

  const submitStage = async () => {
    setSubmitting(true)
    await fetch("/.netlify/functions/submitStage", {
      method: "POST",
      body: JSON.stringify({
        name: user["https://ilana.uk/metadata"].name,
        title: `${user["https://ilana.uk/metadata"].name}`,
        user: user["https://ilana.uk/metadata"].id,
        number: 2,
        expert: expert,
        questions: uploads,
      }),
    })
      .then(res => res.json())
      .then(data => {
        navigate("/community/process")
        setSubmitting(false)
      })
      .catch(error => console.log(error))
  }

  const empty = uploads.length <= 1

  return (
    <Layout>
      <Flex flexDirection="column">
        <Label fontSize={1} color="faded">
          Toile & Pattern
        </Label>
        <Title fontSize={[6, 7]} color="secondary">
          Stage Two
        </Title>
      </Flex>
      <Box px={[0, 6]} my={[3, 4]}>
        <Video url={`${process.env.GATSBY_URL}/videos/stage-two.mp4`} />
      </Box>
      <Flex flexDirection="column" alignItems="flex-start" p={[4, 5, 6]}>
        {template.map((item, key) => (
          <StageQuestion
            key={key}
            id={key}
            title={item.title}
            sub={item.sub}
            images={questions[key]?.uploads}
          />
        ))}

        {empty ? (
          <Box>
            <Button
              bg="black"
              color="white"
              hbg="#2d2d2d"
              hcolor="white"
              onClick={() =>
                alert("Please make sure you have completed all the questions")
              }
            >
              Submit Stage Two ->
            </Button>
          </Box>
        ) : (
          <Box>
            <Button
              bg="black"
              color="white"
              hbg="#2d2d2d"
              hcolor="white"
              onClick={() => submitStage()}
              disabled={submitting}
            >
              {submitting ? "Please Wait..." : "Submit Stage Two ->"}
            </Button>
          </Box>
        )}
      </Flex>
    </Layout>
  )
}

export default StageTwo
