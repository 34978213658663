import create from "zustand"
import { nanoid } from "nanoid"

export const keyGen = async () => {
  let key = await nanoid(10)
  console.log(key)
  return key
}

export const useStore = create(set => ({
  uploads: [],
  clearUploads: () => set({ uploads: [] }),
  addImage: (title, image, uploads) => {
    if (uploads.length === 0) {
      set({
        uploads: [
          {
            _key: nanoid(10),
            title: title,
            _type: "stageQuestion",
            uploads: [
              {
                _type: "imageUpload",
                _key: nanoid(10),
                image: { _type: "image", asset: image },
              },
            ],
          },
        ],
      })
    } else {
      // check if id matches object
      const check = uploads.map(u => u.title).includes(title)

      if (check) {
        const newItems = uploads.map(item => {
          if (title === item.title) {
            return {
              ...item,
              uploads: [
                ...item.uploads,
                {
                  _type: "imageUpload",
                  _key: nanoid(10),
                  image: { _type: "image", asset: image },
                },
              ],
            }
          }
          return item
        })
        set({ uploads: newItems })
      } else {
        set({
          uploads: [
            ...uploads,
            {
              _key: nanoid(10),
              title: title,
              _type: "stageQuestion",
              uploads: [
                {
                  _type: "imageUpload",
                  _key: nanoid(10),
                  image: { _type: "image", asset: image },
                },
              ],
            },
          ],
        })
      }
    }
  },
}))
